import moment from 'moment'
import {
  SET_SELECTED_UNIV_INTAKE,
  SET_REGISTRATION_DATA,
  SET_USER_DATA,
  SET_DASH_ACADEMIC_YEAR,
  SET_SELECTED_LABEL
} from '../mutation-type'
import {
  fileRegistrationData
} from '@/apps/dashboard/utils/formContent'
import request from '@/utils/request'
import { getUrl } from '@/utils/common/getUrl'
import {
  API_DASH_GET_FINANCIAL,
  API_DASH_ACADEMIC_YEAR_LIST
} from '@/constants/apis'

const state = {
  selectedUnivIntake: {},
  registrationData: {},
  userData: {},
  dashAcademicYear: [],
  selectedLabel: {}

}

const mutations = {
  [SET_SELECTED_UNIV_INTAKE] (_state, data) {
    _state.selectedUnivIntake = data
  },
  [SET_REGISTRATION_DATA] (_state, data) {
    _state.registrationData = data
  },
  [SET_USER_DATA] (_state, data) {
    _state.userData = data
  },
  [SET_DASH_ACADEMIC_YEAR] (_state, data) {
    const academic = []
    data.list.map(itm => {
      const startDate = moment(new Date(itm.start_date * 1000)).locale('id').format('DD MMM YYYY')
      const endDate = moment(new Date(itm.end_date * 1000)).locale('id').format('DD MMM YYYY')
      itm.title = `${itm.season} (${startDate} - ${endDate})`
      academic.push(itm)
    })
    _state.dashAcademicYear = academic
  },
  [SET_SELECTED_LABEL] (_state, data) {
    _state.selectedLabel = data
  }
}

const actions = {
  getRegistrationData ({commit}) {
    const data = {
      fileRegistrationData: JSON.parse(JSON.stringify(fileRegistrationData))
    }
    request(API_DASH_GET_FINANCIAL, {}, {methods: 'GET'}).then(res => {
      if (res.success) {
        const detail = res.detail || {}
        data.fileRegistrationData.map(async itm => {
          if (detail[itm.fields] && itm.type === 'file') {
            const res = await fetch(detail[itm.fields])
            const blobFile = await res.blob()
            const ext = getUrl(detail[itm.fields])
            itm.value = new File([blobFile], `file.${ext}`)
            itm.path = detail[itm.fields]
          } else if (detail[itm.fields] > 0 && itm.type === 'number') {
            itm.value = detail[itm.fields]
          }
        })
      }
    })
    commit(SET_REGISTRATION_DATA, data)
  },
  setUserData ({commit}, data) {
    commit(SET_USER_DATA, data)
  },
  getDashAcademicYear ({commit}) {
    const params = {
      page: 1, 
      limit: 300
    }
    return new Promise((_resolve, _reject) => {
      request(API_DASH_ACADEMIC_YEAR_LIST, params).then(res => {
        if (res.success) {
          commit(SET_DASH_ACADEMIC_YEAR, res)
        } 
        _resolve(res)
      })
    })
  },
  setSelectedLabel ({commit}, data) {
    commit(SET_SELECTED_LABEL, data)
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
